<template>
  <div class="h-full w-full">
    <div
      class="flex items-center text-teal mt-3 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div class="grid grid-cols-3 gap-5 h-full w-full">
      <!-- Left Side -->
      <div class="col-span-2 grid gap-y-4">
        <!-- New Hire Section -->
        <div class="flex w-full my-2">
          <div class="bg-lightGrey rounded-xl ml-2 py-2 w-full">
            <h3 class="text-lg text-grey pl-2 pt-1">
              Requesting A New Staff Hire
            </h3>
            <div class="flex flex-col m-auto w-11.5/12">
              <div class="flex w-full mt-10 mb-5 items-center">
                <label for="jobTitle" class="w-3/12">Job Title:</label>

                <div class="w-9/12 md:w-11/12 relative">
                  <input
                    :placeholder="'Enter Title...'"
                    v-model="hiringRequest.job_title"
                    type="text"
                    class="border rounded-md p-2 w-full"
                    maxlength="50"
                    :class="
                      v$.hiringRequest.job_title.$error === true
                        ? 'border-red'
                        : 'border-darkGrey'
                    "
                  />
                  <span
                    class="pl-2 text-xs text-red"
                    v-if="v$.hiringRequest.job_title.$error === true"
                  >
                    {{ v$.hiringRequest.job_title.required.$message }}
                  </span>
                </div>
              </div>
              <div class="flex w-full mb-5 items-center">
                <label for="jobTitle" class="w-3/12">Roles:</label>

                <div class="w-9/12 md:w-11/12 relative">
                  <v-select
                    :options="getRoles"
                    :filterable="true"
                    :placeholder="'Please select...'"
                    @open="onOpen"
                    @close="onClose"
                    v-model="hiringRequest.role"
                    :class="
                      v$.hiringRequest.role.$error === true
                        ? 'style-chooser-error'
                        : 'style-chooser'
                    "
                  >
                    <template #list-footer>
                      <li v-show="rolesHasNextPage" ref="load" class="loader">
                        Loading more options...
                      </li>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="flex w-full mb-5 items-center">
                <label for="contractType" class="w-3/12">Contract Type:</label>
                <select
                  v-model="hiringRequest.contract_type"
                  name="contractType"
                  class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                  :class="
                    v$.hiringRequest.contract_type.$error === true
                      ? 'border-red'
                      : 'border-darkGrey'
                  "
                >
                  <option :value="undefined" selected disabled>
                    Please Select...
                  </option>
                  <option value="permanent">Permanent</option>
                  <option value="casual">Casual</option>
                  <option value="fixed-term">Fixed Term</option>
                  <option value="zero-hour">Zero-Hour</option>
                </select>
              </div>
              <div class="flex w-full mb-5 items-center">
                <label for="department" class="w-3/12">Department:</label>
                <select
                  name="department"
                  v-model="hiringRequest.department"
                  class="rounded-lg border bg-white w-full md:w-11/12 h-10 pl-2"
                  :class="
                    v$.hiringRequest.department.$error === true
                      ? 'border-red'
                      : 'border-darkGrey'
                  "
                >
                  <option :value="undefined" selected disabled>
                    Please Select...
                  </option>
                  <option
                    v-for="department in getPracticeDepartments"
                    :key="department.id"
                    :value="department.id"
                  >
                    {{ department.name }}
                  </option>
                </select>
              </div>
              <div class="flex w-full mb-5 items-center">
                <label for="reportingTo" class="w-3/12">Reporting To:</label>
                <select
                  v-model="hiringRequest.reporting_to"
                  name="reportingTo"
                  class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                  :class="
                    v$.hiringRequest.reporting_to.$error === true
                      ? 'border-red'
                      : 'border-darkGrey'
                  "
                >
                  <option :value="undefined" selected disabled>
                    Please Select...
                  </option>
                  <option
                    v-for="department in getPracticeDepartments"
                    :key="department.id"
                    :value="department.department_head"
                  >
                    {{
                      `${department.department_head.profile.first_name} ${department.department_head.profile.last_name}
                    (${department.department_head.email})`
                    }}
                  </option>
                </select>
              </div>
              <div class="flex w-full mb-2 items-center">
                <label for="startDate" class="w-3/12">Starting Date</label>
                <input
                  v-model="hiringRequest.start_date"
                  name="startDate"
                  type="date"
                  max="2030-12-31"
                  class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                  :class="
                    v$.hiringRequest.start_date.$error === true
                      ? 'border-red'
                      : 'border-darkGrey'
                  "
                />
              </div>
              <span
                class="pl-20 ml-16 text-xs text-red"
                v-if="v$.hiringRequest.start_date.$error === true"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ v$.hiringRequest.start_date.minValue.$message }}
              </span>
              <div class="flex w-full mt-2 items-center">
                <label for="startingSalary" class="w-3/12"
                  >Starting Salary</label
                >
                <select
                  v-model="hiringRequest.starting_salary"
                  name="startingSalary"
                  class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                  :class="
                    v$.hiringRequest.starting_salary.$error === true
                      ? 'border-red'
                      : 'border-darkGrey'
                  "
                >
                  <option :value="undefined" selected disabled>
                    Please Select...
                  </option>
                  <option value="9.5£ - 10.5£/hour">9.5£ - 10.5£/hour</option>
                  <option value="10.5£ - 11.5£/hour">10.5£ - 11.5£/hour</option>
                  <option value="11.5£ - 12.5£/hour">11.5£ - 12.5£/hour</option>
                  <option value="12.5£ - 13.5£/hour">12.5£ - 13.5£/hour</option>
                  <option value="13.5£ - 14.5£/hour">13.5£ - 14.5£/hour</option>
                  <option value="14.5£ - 15.5£/hour">14.5£ - 15.5£/hour</option>
                  <option value="15.5£ - 16.5£/hour">15.5£ - 16.5£/hour</option>
                  <option value="16.5£ - 17.5£/hour">16.5£ - 17.5£/hour</option>
                  <option value="17.5£ - 18.5£/hour">17.5£ - 18.5£/hour</option>
                  <option value="18.5£ - 19.5£/hour">18.5£ - 19.5£/hour</option>
                  <option value="19.5£ - 20.5£/hour">19.5£ - 20.5£/hour</option>
                  <option value="20.5£ - 21.5£/hour">20.5£ - 21.5£/hour</option>
                  <option value="21.5£ - 22.5£/hour">21.5£ - 22.5£/hour</option>
                  <option value="22.5£ - 23.5£/hour">22.5£ - 23.5£/hour</option>
                  <option value="23.5£ - 24.5£/hour">23.5£ - 24.5£/hour</option>
                  <option value="24.5£ - 30£/hour">24.5£ - 30£/hour</option>
                  <option value="30£ - 35£/hour">30£ - 35£/hour</option>
                  <option value="35£ - 40£/hour">35£ - 40£/hour</option>
                  <option value="40£ - 455£/hour">40£ - 45£/hour</option>
                  <option value="45£ - 50£/hour">45£ - 50£/hour</option>
                  <option value="50£ - 55£/hour">50£ - 55£/hour</option>
                  <option value="55£ - 60£/hour">55£ - 60£/hour</option>
                  <option value="65£ - 70£/hour">65£ - 70£/hour</option>
                  <option value="70£ - 75£/hour">70£ - 75£/hour</option>
                  <option value="75£ - 80£/hour">75£ - 80£/hour</option>
                  <option value="80£ - 85£/hour">80£ - 85£/hour</option>
                  <option value="85£ - 90£/hour">85£ - 90£/hour</option>
                  <option value="90£ - 95£/hour">90£ - 95£/hour</option>
                  <option value="95£ - 100£/hour">95£ - 100£/hour</option>
                  <option value="100£ - 120£/hour">100£ - 120£/hour</option>
                  <option value="120£ - 140£/hour">120£ - 140£/hour</option>
                  <option value="140£ - 160£/hour">140£ - 160£/hour</option>
                  <option value="160£ - 180£/hour">160£ - 180£/hour</option>
                  <option value="180£ - 200£/hour">180£ - 200£/hour</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- Rota Information -->
        <div class="flex w-full mb-2">
          <rota-card
            class="w-full"
            @selectedPattern="updateSelectedPattern"
            :showSelect="true"
            :showTitle="true"
            :border="
              v$.hiringRequest.rota_information.$error === true
                ? 'border-red'
                : 'border-darkGrey'
            "
          ></rota-card>
        </div>
        <!-- Reason for Recruitment -->
        <div class="flex w-full mb-2 overflow-hidden">
          <div class="row-span-1 pb-2 bg-lightGrey rounded-xl ml-2 w-full">
            <label for="reasonForRecruitment" class="text-lg text-grey pl-2"
              >Reason For Recruitment</label
            >
            <div class="flex w-full ml-2 my-2">
              <select
                v-model="hiringRequest.reason_for_recruitment"
                name="reasonForRecruitment"
                class="w-11.5/12 rounded-lg border bg-white h-10 pl-2"
                :class="
                  v$.hiringRequest.reason_for_recruitment.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option value="New Position">New Position</option>
                <option value="Business Growth">Business Growth</option>
                <option value="Skill Gap">Skill Gap</option>
                <option value="Replace Existing">Replace Existing</option>
                <option value="Meternity Cover">Maternity Cover</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div
              class="sm:flex-col bg-white w-11.5/12 m-2 border"
              :class="
                v$.hiringRequest.comment.$error === true
                  ? 'border-red'
                  : 'border-darkGrey'
              "
            >
              <QuillEditor
                placeholder="Enter Comments"
                style="min-height: 150px"
                toolbar="essential"
                content-type="html"
                theme="snow"
                v-model:content="hiringRequest.comment"
              />
            </div>
            <span
              class="pl-4 text-xs text-red"
              v-if="v$.hiringRequest.comment.maxLength.$invalid === true"
            >
              {{ v$.hiringRequest.comment.maxLength.$message }}
            </span>
          </div>
        </div>
      </div>
      <!-- Right Side -->
      <div class="flex flex-col justify-start space-y-5">
        <!-- Specifications -->
        <div class="bg-lightGrey rounded-xl mt-2 flex flex-col py-4">
          <h3 class="text-lg text-grey pl-2">Specifications</h3>
          <div class="">
            <div class="flex-col w-11.5/12 my-2 m-auto">
              <label for="jobSpecification" class="w-full"
                >Job Specification:</label
              >
              <select
                v-if="!jobSpecIsLoading"
                name="jobSpecification"
                v-model="hiringRequest.job_specification"
                @change="fetchSingleJobSpec"
                class="rounded-lg border bg-white w-full h-10 pl-2"
                :class="
                  v$.hiringRequest.job_specification.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option
                  v-for="jobSpec in getJobSpecifications"
                  :key="jobSpec.id"
                  :value="jobSpec.id"
                >
                  {{ jobSpec.title }}
                </option>
              </select>
              <!-- Edix Existing / Create New icons -->
              <!-- <div class="flex justify-end text-center">
                <div class="mx-2 cursor-pointer">
                  <span class="material-icons text-xl text-teal"> edit </span>
                  <p class="underline">Edit Existing</p>
                </div>
                <div class="mx-2 cursor-pointer">
                  <span class="material-icons text-xl text-teal">
                    add_circle
                  </span>
                  <p class="underline">Create new</p>
                </div>
              </div> -->
            </div>
          </div>
          <div class="">
            <div class="flex-col w-11.5/12 m-auto">
              <label for="personSpecification" class="w-full"
                >Person Specification:</label
              >
              <select
                v-if="!personSpecIsLoading"
                name="personSpecification"
                v-model="hiringRequest.person_specification"
                @change="fetchSinglePersonSpec"
                class="rounded-lg border bg-white w-full h-10 pl-2"
                :class="
                  v$.hiringRequest.person_specification.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option
                  v-for="personSpec in getPersonSpecifications"
                  :key="personSpec.id"
                  :value="personSpec.id"
                >
                  {{ personSpec.name }}
                </option>
              </select>
              <!-- Edix Existing / Create New icons -->
              <!-- <div class="flex justify-end text-center">
                <div class="mx-2 cursor-pointer">
                  <span class="material-icons text-xl text-teal"> edit </span>
                  <p class="underline">Edit Existing</p>
                </div>
                <div class="mx-2 cursor-pointer">
                  <span class="material-icons text-xl text-teal">
                    add_circle
                  </span>
                  <p class="underline">Create new</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Job Specification Preview -->
        <div class="bg-lightGrey rounded-xl pb-2 mb-3">
          <!-- Title -->
          <div class="flex items-center pl-2 pt-2 justify-between">
            <h3 class="text-lg text-grey">Job Specification Preview</h3>
            <!-- <span class="flex items-center mr-2 cursor-pointer">
              <p class="mr-1">View in full</p>
              <span class="material-icons text-xl text-teal"> visibility </span>
            </span> -->
          </div>
          <div class="ml-2 mt-2">
            <h4>Responsibilities</h4>
            <table
              v-if="
                getSingleJobSpecification &&
                getSingleJobSpecification.responsibilities.length
              "
              class="border border-black w-11.5/12 mt-1"
            >
              <tbody>
                <tr
                  class="border border-black"
                  v-for="(
                    responsibility, index
                  ) in getSingleJobSpecification.responsibilities"
                  :key="responsibility.id"
                >
                  <td class="border border-black w-1/12">{{ index + 1 }}</td>
                  <td class="border border-black pl-1">
                    {{ responsibility.responsibility }}
                  </td>
                </tr>
              </tbody>
            </table>
            <no-data-found v-else />
          </div>
          <!-- Person Specification -->
          <div class="ml-2 mt-2">
            <h4>Person Specification Preview</h4>
            <table
              v-if="getSinglePersonSpecification"
              class="border border-black w-11.5/12 mt-1"
            >
              <thead>
                <tr>
                  <th class="w-1/5 border border-black font-medium">
                    Attributes
                  </th>
                  <th class="w-2/5 border border-black font-medium">
                    Essential
                  </th>
                  <th class="w-2/5 border border-black font-medium">
                    Desirable
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="border border-black text-teal text-center"
                  v-for="spec in getSinglePersonSpecification.person_specification_attributes"
                  :key="spec.id"
                >
                  <td class="border border-black w-1/12">
                    {{ spec.attribute }}
                  </td>
                  <td class="border border-black bg-white">
                    {{ spec.essential }}
                  </td>
                  <td class="border border-black bg-white">
                    {{ spec.desirable }}
                  </td>
                </tr>
              </tbody>
            </table>
            <no-data-found v-else />
          </div>
        </div>
        <div class="flex justify-center">
          <!-- <router-link :to="{ name: 'ma-new-staff-summary' }"> -->
          <base-button @click="commitData">Preview</base-button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxLength } from "@vuelidate/validators";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import rotaCard from "../../../../components/ui/baseComponents/rotaCard.vue";
export default {
  components: { QuillEditor, rotaCard, noDataFound },
  mounted() {
    this.hiringRequest = this.getNewHiringRequest;
    this.hiringRequest.practice = localStorage.getItem("practice");
    this.hiringRequest.role = null;
    this.hiringRequest.rota_information = null;
  },
  beforeRouteLeave(to) {
    if (to.name !== "ma-new-staff-summary") {
      // Set Rota information to null
      this.$store.commit("profile/setWorkTimings", null);
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      personSpecIsLoading: false,
      singlePersonSpecIsLoading: false,
      jobSpecIsLoading: false,
      singleJobSpecIsLoading: false,
      rolesPage: 1,
      roleData: [],
      observer: null,
      hiringRequest: {
        practice: localStorage.getItem("practice"),
        role: null,
        job_title: null,
        contract_type: null,
        department: null,
        reporting_to: null,
        start_date: null,
        starting_salary: null,
        reason_for_recruitment: null,
        comment: "",
        job_specification: null,
        person_specification: null,
        rota_information: null,
        // name: null,
        // start_time: null,
        // end_time: null,
        // break_time: null,
        // repeat_days: null,
        singlePersonSpec: null,
        singleJobSpec: null,
      },
    };
  },
  validations() {
    return {
      hiringRequest: {
        role: { required },
        job_title: {
          required: helpers.withMessage("Required (max 120 chars.)", required),
        },
        contract_type: { required },
        department: { required },
        reporting_to: { required },
        start_date: {
          required,
          minValue: helpers.withMessage(
            "Required (Should be greater than today.)",
            (value) => value > new Date().toISOString()
          ),
          maxValue: (value) =>
            value < this.addYears(new Date(), 101).toISOString(),
        },
        starting_salary: { required },
        reason_for_recruitment: { required },
        comment: {
          required: required,
          maxLength: helpers.withMessage(
            "Required (max 200 chars.)",
            maxLength(199)
          ),
        },
        job_specification: { required },
        person_specification: { required },
        rota_information: { required },
        // name: { required },
        // start_time: { required },
        // end_time: { required },
        // break_time: { required },
        // repeat_days: { required },
      },
    };
  },
  created() {
    this.fetchPracticeDepartaments();
    this.fetchJobSpecifications();
    this.fetchPersonSpecifications();
    this.fetchWorkPatterns();
    this.loadRoles(this.rolesPage);
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    reRoute() {
      this.$router.back();
    },
    updateSelectedPattern(data) {
      this.hiringRequest.rota_information = data;
    },
    async onOpen() {
      if (this.rolesHasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.rolesPage++;
        this.loadRoles(this.rolesPage);
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async loadRoles(rolesPage) {
      try {
        await this.$store.dispatch("admin/loadRoles", rolesPage);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchWorkPatterns() {
      try {
        await this.$store.dispatch("profile/fetchWorkPatterns");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchPracticeDepartaments() {
      try {
        await this.$store.dispatch("MAnewHire/fetchPracticeDepartaments", {
          practice: localStorage.getItem("practice"),
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchJobSpecifications() {
      try {
        (this.jobSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchJobSpecifications", {
            practice: localStorage.getItem("practice"),
          });
        this.jobSpecIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchPersonSpecifications() {
      try {
        (this.personSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchPersonSpecifications", {
            practice: localStorage.getItem("practice"),
          });
        this.personSpecIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchSinglePersonSpec() {
      try {
        (this.singlePersonSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchSinglePersonSpec", {
            person_specification: this.hiringRequest.person_specification,
          });
        this.singlePersonSpecIsLoading = false;
        this.hiringRequest.singlePersonSpec = this.getSinglePersonSpecification;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchSingleJobSpec() {
      try {
        (this.singleJobSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchSingleJobSpec", {
            job_specification: this.hiringRequest.job_specification,
          });
        this.singleJobSpecIsLoading = false;
        this.hiringRequest.singleJobSpec = this.getSingleJobSpecification;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    commitData() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.hiringRequest.role = this.hiringRequest.role.code;
        this.$store.commit("MAnewHire/setNewHiringRequest", this.hiringRequest);
        this.$router.push({ name: "ma-new-staff-summary" });
      } else {
        return;
      }
    },
    onRoleSelect(value) {
      this.hiringRequest.role = value;
    },
    addYears(date, years) {
      date.setFullYear(date.getFullYear() + years);
      return date;
    },
  },
  computed: {
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },
    rolesHasNextPage() {
      // Determine how many pages
      const data = this.$store.getters["admin/getRoles"];
      const totalPages = Math.ceil(data.total / 10);
      if (this.rolesPage <= totalPages) {
        return true;
      }
      return false;
    },
    getNewHiringRequest() {
      return this.$store.getters["MAnewHire/getNewHiringRequest"];
    },
    getWorkPatterns() {
      return this.$store.getters["profile/getWorkPatterns"];
    },
    getWorkTimings() {
      return this.$store.getters["profile/getWorkTimings"];
    },
    getPracticeDepartments() {
      return this.$store.getters["MAnewHire/getPracticeDepartments"];
    },
    getJobSpecifications() {
      return this.$store.getters["MAnewHire/getJobSpecifications"];
    },
    getSingleJobSpecification() {
      return this.$store.getters["MAnewHire/getSingleJobSpecification"];
    },
    getPersonSpecifications() {
      return this.$store.getters["MAnewHire/getPersonSpecifications"];
    },
    getSinglePersonSpecification() {
      return this.$store.getters["MAnewHire/getSinglePersonSpecification"];
    },
  },
};
</script>

<style scoped>
/* #forHeight {
  height: calc(100vh - 84px);
} */
input[type="checkbox"]:checked ~ div {
  background-color: #ed8b00;
  color: white;
}
</style>
<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #425563;
  height: 10rem;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}

.style-chooser .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}

.style-chooser-error .vs__dropdown-toggle,
.style-chooser-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
  height: 10rem;
}

.style-chooser-error .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}

.style-chooser-error .vs__clear,
.style-chooser-error .vs__open-indicator {
  fill: black;
}
</style>
